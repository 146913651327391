:root {
  /* Dark theme colors */
  --dark-bg: #1E2132;
  --dark-text: #F6B17A;
  --dark-primary: #424769;
  --dark-secondary: #7077A1;
  --dark-accent: #F6B17A;
  --dark-contrast: #F5E8C7;

  /* Light theme colors */
  --light-bg: #F5F5DC;
  --light-text: #FFBF78;
  --light-primary: #FFEEA9;
  --light-secondary: #FFEEA9;
  --light-accent: #FFBF78;
  --light-contrast: #FF7D29;
}

body {
  transition: background-color 0.3s, color 0.3s;
}

.light {
  background-color: var(--light-bg);
  color: var(--light-text);
}

.dark {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

/* Theme toggle styles */
.theme-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
}

.light .theme-toggle {
  color: var(--light-primary);
}

.dark .theme-toggle {
  color: var(--dark-contrast);
}

/* Apply theme colors to components */
.light .skills-banner, .light .projects-banner {
  background-color: var(--light-primary);
  color: var(--light-highlight);
}

.dark .skills-banner, .dark .projects-banner {
  background-color: var(--dark-primary);
  color: var(--dark-highlight);
}

.light .skills-container, .light .experience-item, .light .research-item {
  background-color: var(--light-secondary);
}

.dark .skills-container, .dark .experience-item, .dark .research-item {
  background-color: var(--dark-secondary);
}

.light .project-link, .light .contact-link {
  color: var(--light-text);
  border-color: var(--light-accent);
}

.dark .project-link, .dark .contact-link {
  color: var(--dark-text);
  border-color: var(--dark-accent);
}

.light .project-link:hover, .light .contact-link:hover {
  background-color: var(--light-accent);
  color: var(--light-bg);
}

.dark .project-link:hover, .dark .contact-link:hover {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}