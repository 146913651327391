* {
  box-sizing: border-box;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.App {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
}

/* Header styles */
.header {
  margin-bottom: 5rem;
  flex: 1;
  margin-right: 2rem;
  margin-left: 2rem;
}

.header-text {
  padding-top: 4rem; /* Adjust this value to move the text lower */
}

.header h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.header p {
  font-size: 1.2rem;
  color: #666;
}

/* Skills section */
.skills-section {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.skills-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: 0.1rem;
}

.greeting-animation {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 0.1rem;
}

.hi-animation {
  width: 60px;
  height: 60px;
  margin-right: 0.75rem;
}

.greeting-text {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--light-text);
  padding-bottom: 0em;
  
}

.dark .greeting-text {
  color: var(--dark-text);
}

.skills-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.illustration {
  flex: 1;
  max-width: 50%;
  min-width: 300px;
}

.skills-container {
  flex: 1;
  max-width: 50%;
  min-width: 300px;
}

.skills-banner {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background-color: var(--light-primary);
  color: var(--light-highlight);
}

.dark .skills-banner {
  background-color: var(--dark-primary);
  color: var(--dark-highlight);
}

.skills-list {
  padding: 20px;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--light-secondary);
}

.dark .skills-list {
  background-color: var(--dark-secondary);
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.skill-name {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.skill-name svg {
  margin-right: 0.5rem;
  font-size: 1.2em;
}

.skill-bar-container {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

:root {
  --skill-bar-color-light: #4CAF50;
  --skill-bar-color-dark: #F6B17A;
}

.skill-bar {
  height: 100%;
  transition: width 0.5s ease-in-out, background-color 0.3s ease;
  background-color: var(--light-accent);
}

.dark .skill-bar {
  background-color: var(--dark-accent);
}

.skill-bar.filled {
  background-color: #4CAF50;
}

.skill-bar.empty {
  background-color: transparent;
}

.skill-score {
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: var(--light-text);
}

.dark .skill-score {
  color: var(--dark-text);
}

/* Projects section */
.projects-section {
  margin-top: 50px;
}

.projects-banner {
  text-align: center;
  padding: 30px 0;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 0 20px;
}

.project {
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.project:hover {
  transform: translateY(-5px);
}

.project-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  color: white;
}

.project-link {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: --dark-bg;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.project-link:hover {
  background-color: #2980b9;
}

/* Experience & Research Section */
.experience-research-section {
  margin-top: 50px;
  padding: 0 5%; /* Add padding on the left and right */
}

.experience-research-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px; /* Limit the maximum width */
  margin: 0 auto; /* Center the container */
}

.experience-item, .research-item {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: var(--light-bg);
  color: var(--light-text);
}

.dark .experience-item, .dark .research-item {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.experience-item:hover, .research-item:hover {
  background-color: var(--light-text);
  color: var(--light-bg);
}

.dark .experience-item:hover, .dark .research-item:hover {
  background-color: var(--dark-text);
  color: var(--dark-bg);
}

/* Contact section */
.contact-section {
  margin-top: 50px;
  text-align: center;
  padding: 30px;
  border-radius: 15px;
  position: relative;
  background-color: var(--light-bg);
  width: 80%;  /* Adjust this value as needed */
  max-width: 800px;  /* Adjust this value as needed */
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--light-muted);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.dark .contact-section {
  background-color: var(--dark-bg);
  border-color: var(--dark-secondary);
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
  width: 100%;
}

.contact-link {
  padding: 8px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  flex: 1;
  text-align: center;
  background-color: var(--light-bg);
  color: var(--light-text);
  text-decoration: none;
}

.dark .contact-link {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.contact-link:hover {
  background-color: var(--light-accent);
  color: var(--light-bg);
}

.dark .contact-link:hover {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}

/* Responsive design */
@media (max-width: 1024px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .experience-research-section {
    padding: 0 5%; /* Reduce padding for smaller screens */
  }
}

@media (max-width: 768px) {
  .projects-container, .skills-container, .experience-research-container {
    grid-template-columns: 1fr;
  }

  .skills-content {
    flex-direction: column;
  }

  .illustration,
  .skills-container {
    max-width: 100%;
  }

  .experience-research-section {
    padding: 0 3%; /* Further reduce padding for mobile screens */
  }
}

.contact-form {
  width: calc(100% - 20px);
  max-width: 800px;
  margin: 10px auto;
  padding: 15px;
  border-radius: 8px;
  background-color: var(--light-bg);
  color: var(--light-text);
}

.dark .contact-form {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.contact-form h3 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--light-muted);
  border-radius: 4px;
  background-color: var(--light-bg);
  color: var(--light-text);
}

.dark .form-group input,
.dark .form-group textarea {
  border-color: var(--dark-muted);
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.form-group textarea {
  height: 100px;
  resize: vertical;
}

.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  flex: 1;
  text-align: center;
  background-color: var(--light-bg);
  color: var(--light-text);
  border: 1px solid var(--light-accent);
  cursor: pointer;
  font-size: 1em;
  text-decoration: none;
}

.dark .submit-btn {
  background-color: var(--dark-bg);
  color: var(--dark-text);
  border-color: var(--dark-accent);
}

.submit-btn:hover {
  background-color: var(--light-accent);
  color: var(--light-bg);
  transform: scale(1.05);
}

.dark .submit-btn:hover {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}

.submit-btn svg {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-form {
    max-width: 100%;
    margin-top: 2rem;
  }
}

.error-message {
  color: #ff0000;
  font-size: 0.8em;
  margin-top: 0.2rem;
}

.status-message {
  margin-top: 1rem;
  font-weight: bold;
}

.status-message.success {
  color: #4CAF50;
}

.status-message.error {
  color: #ff0000;
}

.greeting-animation {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.hi-animation {
  width: 50px;
  height: 50px;
  margin-right: 0.5rem;
}

.greeting-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--light-text);
}

.dark .greeting-text {
  color: var(--dark-text);
}

.header-greeting-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  gap: 1rem;
  padding: 0.5rem;
}

.header {
  flex: 1;
  max-width: calc(50% - 0.5rem);
  margin-bottom: 0.5rem;
}

.greeting-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: 2px solid var(--light-accent);
  border-radius: 10px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: calc(50% - 0.5rem);
  margin-bottom: 0.5rem;
}

.dark .greeting-container {
  border-color: var(--dark-accent);
}
@media (max-width: 768px) {
  .greeting-container {
    max-width: 90%; /* Increased from 60% to 90% */
    padding: 2rem; /* Increased padding for mobile */
  }

  .greeting-text {
    font-size: 1.8rem; /* Slightly reduced font size for mobile */
  }

  .brief-description {
    font-size: 1rem; /* Adjusted font size for mobile */
  }

  .hi-animation {
    width: 50px; /* Slightly reduced size for mobile */
    height: 50px;
  }
}

.greeting-animation {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.hi-animation {
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
}

.greeting-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--light-text);
}

.dark .greeting-text {
  color: var(--dark-text);
}

.passions-section {
  margin-top: 0.5rem;
}

.passions-section h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: var(--light-text);
}

.dark .passions-section h3 {
  color: var(--dark-text);
}

.passions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem; /* Increased from 0.5rem to 2rem */
}

.passion-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--light-primary);
  padding: 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  
}

.dark .passion-item {
  background-color: var(--dark-primary);
}

.passion-item:hover {
  background-color: var(--light-accent);
}

.dark .passion-item:hover {
  background-color: var(--dark-accent);
}

.passion-icon {
  font-size: 1.5rem;
  color: var(--light-accent);
  transition: color 0.3s ease;
}

.dark .passion-icon {
  color: var(--dark-accent);
}

.passion-item:hover .passion-icon {
  color: var(--light-primary);
}

.dark .passion-item:hover .passion-icon {
  color: var(--dark-primary);
}

.passion-text {
  font-size: 0.9rem;
  color: var(--light-text);
  transition: color 0.3s ease;
}

.dark .passion-text {
  color: var(--dark-text);
}

.passion-item:hover .passion-text {
  color: var(--light-bg);
}

.dark .passion-item:hover .passion-text {
  color: var(--dark-bg);
}

@media (max-width: 1240px) {
  .App {
    max-width: 100%;
    padding: 20px 5%;
  }
}

@media (max-width: 768px) {
  .App {
    padding: 20px 3%;
  }

  .header-greeting-container {
    flex-direction: column;
  }

  .header, .greeting-container {
    max-width: 100%;
  }
}

.greeting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  margin: 0;
  background-color: transparent;
  border: 2px solid var(--light-accent);
  border-radius: 10px;
  box-sizing: border-box;
}

.dark .greeting-container{
  border: 2px solid var(--dark-accent);
}

.greeting-main {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  width: 100%;
}

.hi-animation {
  width: 60px;
  height: 60px;
  margin-right: 0.5rem;
}

.greeting-text {
  font-size: 3rem;
  font-weight: bold;
  color: var(--light-text);
  margin-left: 0.5rem;
  padding-top: 1rem;
}

.dark .greeting-text {
  color: var(--dark-text);
}

.passions-wrapper {
  width: 100%;
  margin-top: 0.25rem;
}

.brief-description {
  text-align: center;
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #333;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.passions-section {
  margin-top: 1rem;
}

.passions-container {
  display: flex;
  justify-content: center;
  gap: 2rem; /* Increased from 1rem to 2rem */
  margin-top: 0.5rem;
}

.passion-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passion-icon {
  font-size: 1.5rem;
  color: #333; /* Adjust this color as needed */
}

.passion-text {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #555; /* Adjust this color as needed */
}

.greeting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem; /* Reduces space between elements */
}

.greeting-main {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.hi-animation {
  width: 50px; /* Adjust as needed */
}

.greeting-text {
  font-size: 1.5rem;
  margin: 0;
}

.brief-description {
  text-align: center;
  font-size: 1rem;
  color: #333;
  max-width: 600px;
  margin: 0.5rem 0;
}

.larger-text {
  font-size: 1.2rem; /* Aumenta la dimensione del testo del 20% */
  line-height: 1.5; /* Mantiene una spaziatura proporzionale tra le righe */
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  width: auto;
}

.navbar-content {
  background-color: var(--light-bg);
  border-radius: 0 0 10px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.dark .navbar-content {
  background-color: var(--dark-bg);
}

.navbar.visible .navbar-content {
  width: 250px;
  padding: 10px;
}

.navbar-toggle {
  background-color: transparent;
  color: var(--light-text);
  border: none;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 24px;
}

.dark .navbar-toggle {
  color: var(--dark-text);
}

.navbar-toggle:hover {
  background-color: var(--light-accent);
  color: var(--light-bg);
}

.dark .navbar-toggle:hover {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}

.navbar-menu {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
}

.navbar-button {
  background-color: transparent;
  color: var(--light-text);
  border: none;
  padding: 10px 15px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 100%;
  text-align: left;
  border-radius: 5px;
}

.dark .navbar-button {
  color: var(--dark-text);
}

.navbar-button:hover {
  background-color: var(--light-accent);
  color: var(--light-bg);
}

.dark .navbar-button:hover {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}

.recommended-books-section {
  margin-top: 50px;
  padding: 0 5%; /* Add this line to increase padding on both sides */
}

.section-banner {
  margin-top: 3rem; /* Add this line to increase top margin */
  margin-bottom: 2rem; /* Add this line for consistent spacing */
  text-align: center;
}

.section-banner h2 {
  font-size: 2rem; /* Adjust as needed */
  color: var( --light-accent);
}

.dark .section-banner h2 {
  color: var(--dark-accent);
}

.books-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px 0;
}

.book-item {
  background-color: var(--light-secondary);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.book-item:hover {
  transform: translateY(-5px);
}

.book-cover {
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.book-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.default-cover {
  font-size: 4em;
  color: #999;
}

.book-content {
  padding: 20px;
}

.book-content h3 {
  margin-top: 0;
  color: var(--light-primary);
}

.book-content h4 {
  color: var(--light-accent);
  margin-top: 5px;
}

.book-content p {
  margin-top: 10px;
}

.dark .book-item {
  background-color: var(--dark-secondary);
}

.dark .book-content h3 {
  color: var(--dark-accent);
}

.dark .book-content h4 {
  color: var(--dark-contrast);
}

.see-all-container {
  display: flex;
  justify-content: center;
  margin-top: 0rem;
}

.see-all-books {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
  color: var(--light-text);
  border: 1px solid var(--light-accent);
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.3s ease;
  margin-top: 0rem;

}

.dark .see-all-books {
  color: var(--dark-text);
  border-color: var(--dark-accent);
}

.see-all-books:hover {
  background-color: var(--light-accent);
  color: var(--light-bg);
}

.dark .see-all-books:hover {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}

.see-all-books .fa-arrow-right {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .recommended-books-section {
    padding: 0 3%; /* Reduce padding for smaller screens */
  }
}

.genre-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.filter-icon {
  margin-right: 10px;
  color: var(--light-accent);
}

.dark .filter-icon {
  color: var(--dark-accent);
}

.genre-select {
  padding: 8px 12px;
  font-size: 1rem;
  border: 1px solid var(--light-accent);
  border-radius: 5px;
  background-color: var(--light-bg);
  color: var(--light-text);
  cursor: pointer;
  transition: all 0.3s ease;
}

.dark .genre-select {
  border-color: var(--dark-accent);
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.genre-select:hover, .genre-select:focus {
  border-color: var(--light-primary);
  outline: none;
  box-shadow: 0 0 0 2px rgba(38, 139, 210, 0.2);
}

.dark .genre-select:hover, .dark .genre-select:focus {
  border-color: var(--dark-primary);
  box-shadow: 0 0 0 2px rgba(246, 177, 122, 0.2);
}

.genre-select option {
  background-color: var(--light-bg);
  color: var(--light-text);
}

.dark .genre-select option {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.book-genre {
  display: inline-block;
  margin-top: 10px;
  padding: 4px 8px;
  background-color: var(--light-accent);
  color: var(--light-bg);
  font-size: 0.8rem;
  border-radius: 4px;
}

.dark .book-genre {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}

.star {
  color: #e4e5e9;
}

.star.filled {
  color: #ffc107;
}

.back-link {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  background-color: var(--light-bg);
  color: var(--light-text);
  border: 1px solid var(--light-accent);
}

.dark .back-link {
  background-color: var(--dark-bg);
  color: var(--dark-text);
  border-color: var(--dark-accent);
}

.back-link:hover {
  background-color: var(--light-accent);
  color: var(--light-bg);
}

.dark .back-link:hover {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}

.back-icon {
  margin-right: 0.5rem;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .project-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .back-link {
    margin-bottom: 1rem;
  }
}

.project-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.project-details {
  flex: 1;
  max-width: 60%;
}

.project-image-container {
  flex: 1;
  max-width: 40%;
  position: sticky;
  top: 2rem;
}

.project-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .project-container {
    flex-direction: column;
  }

  .project-details,
  .project-image-container {
    max-width: 100%;
  }

  .project-image-container {
    position: static;
    margin-bottom: 1rem;
  }
}

.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.blog-posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.blog-post-preview {
  background-color: var(--light-bg);
  border: 1px solid var(--light-accent);
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dark .blog-post-preview {
  background-color: var(--dark-bg);
  border-color: var(--dark-accent);
}

.blog-post-preview:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.blog-post-preview h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.blog-post-preview h2 a {
  color: var(--light-primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.dark .blog-post-preview h2 a {
  color: var(--dark-primary);
}

.blog-post-preview h2 a:hover {
  color: var(--light-accent);
}

.dark .blog-post-preview h2 a:hover {
  color: var(--dark-accent);
}

.post-date {
  font-size: 0.9rem;
  color: var(--light-muted);
  margin-bottom: 10px;
}

.dark .post-date {
  color: var(--dark-muted);
}

.post-excerpt {
  margin-bottom: 15px;
  color: var(--light-text);
}

.dark .post-excerpt {
  color: var(--dark-text);
}

.read-more {
  display: inline-block;
  padding: 5px 10px;
  background-color: var(--light-accent);
  color: var(--light-bg);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.dark .read-more {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}

.read-more:hover {
  background-color: var(--light-highlight);
}

.dark .read-more:hover {
  background-color: var(--dark-highlight);
}

.blog-loading, .blog-error {
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: var(--light-text);
}

.dark .blog-loading, .dark .blog-error {
  color: var(--dark-text);
}

@media (max-width: 768px) {
  .blog-posts-grid {
    grid-template-columns: 1fr;
  }
}

.recent-blog-posts-section {
  margin-top: 50px;
  padding: 0 5%;
}

.recent-blog-posts-container {
  border: 2px solid var(--light-accent);
  border-radius: 10px;
  padding: 20px;
  background-color: var(--light-bg);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dark .recent-blog-posts-container {
  border-color: var(--dark-accent);
  background-color: var(--dark-bg);
}

.recent-posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.recent-post-item {
  background-color: var(--light-secondary);
  border-radius: 8px;
  padding: 15px;
  transition: transform 0.3s ease;
}

.dark .recent-post-item {
  background-color: var(--dark-secondary);
}

.recent-post-item:hover {
  transform: translateY(-5px);
}

.recent-post-item .post-title {
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}

.recent-post-item .post-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: var(--light-accent);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.dark .recent-post-item .post-title::after {
  background-color: var(--dark-accent);
}

.recent-post-item:hover .post-title::after {
  transform: scaleX(1);
}

.recent-post-item .post-title a {
  color: var(--light-primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.dark .recent-post-item .post-title a {
  color: var(--dark-primary);
}

.recent-post-item .post-title a:hover {
  color: var(--light-accent);
}

.dark .recent-post-item .post-title a:hover {
  color: var(--dark-accent);
}

.recent-post-item .post-date {
  font-size: 0.9rem;
  color: var(--light-muted);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.dark .recent-post-item .post-date {
  color: var(--dark-muted);
}

.recent-post-item .post-date .icon {
  margin-right: 5px;
}

.recent-post-item .post-excerpt {
  margin-bottom: 15px;
  color: var(--light-text);
}

.dark .recent-post-item .post-excerpt {
  color: var(--dark-text);
}

.read-more, .view-all-button {
  display: inline-block;
  padding: 5px 10px;
  background-color: var(--light-accent);
  color: var(--light-bg);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.dark .read-more, .dark .view-all-button {
  background-color: var(--dark-accent);
  color: var(--dark-bg);
}

.read-more:hover, .view-all-button:hover {
  background-color: var(--light-highlight);
}

.dark .read-more:hover, .dark .view-all-button:hover {
  background-color: var(--dark-highlight);
}

.view-all-posts {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .recent-blog-posts-section {
    padding: 0 3%;
  }
  
  .recent-posts-grid {
    grid-template-columns: 1fr;
  }
}

